import React from 'react'
import ReactDom from 'react-dom'
import SVG from 'react-svg'

import MapPopup from './MapPopup'

import { navigate } from 'gatsby'

import countryContent from '../data/country-content'

import styles from '../css-modules/world-map.module.css'

const documentGlobal = typeof document !== 'undefined' && document

class WorldMap extends React.Component {
  state = {
    togglePopup: false,
    mouseX: 0,
    mouseY: 0,
    selectedCountry: '',
    toggleZoom: false,
    mapSize: 0.85,
  }

  handleMapHover = e => {
    console.log(e.target)
  }

  handleMapHoverOut = () => {
    this.setState({
      toggleZoom: false,
    })
  }

  handleZoom = () => {
    this.setState({
      mapSize: this.state.mapSize + 0.2,
    })
  }

  hoverMap = e => {
    const selectedCountry = e.target.getAttribute('title')
    if (
      this.state.selectedCountry !== selectedCountry &&
      selectedCountry !== null
    ) {
      const mouseX = e.pageX - 35
      const mouseY = e.pageY - 155

      this.setState({
        togglePopup: selectedCountry,
        selectedCountry,
        mouseX,
        mouseY,
      })
    }
  }

  unHoverMap = e => {
    const selectedCountry = e.target.getAttribute('title')
    console.log(selectedCountry)
    if (
      selectedCountry !== null &&
      selectedCountry !== this.state.selectedCountry
    ) {
      setTimeout(
        this.setState({
          togglePopup: false,
          selectedCountry: '',
          mouseX: 0,
          mouseY: 0,
        }),
        200
      )
    }
  }

  removePopup = () => {
    this.setState({
      togglePopup: false,
      selectedCountry: '',
      mouseX: 0,
      mouseY: 0,
    })
  }

  render() {
    return (
      <div
        className={styles.map}
        style={{ position: 'relative', width: '120%' }}
        onClick={
          countryContent[this.state.selectedCountry]
            ? () =>
                navigate(`/country`, {
                  state: { country: this.state.selectedCountry },
                })
            : null
        }
        onMouseOver={this.hoverMap}
        onMouseOut={this.unHoverMap}
        onMouseLeave={this.removePopup}
      >
        {/** <div
          onClick={this.handleZoom}
          className={
            this.state.toggleZoom
              ? [styles.zoom, styles.display].join(' ')
              : styles.zoom
          }
        >
          +
        </div>
         */}
        <SVG
          style={{
            transform: `scale(${this.state.mapSize})`,
            transition: 'all .2s ease-in-out',
          }}
          id="world"
          className="world"
          src="/static/world.svg"
        />
        {/*documentGlobal
          ? ReactDom.createPortal(
              <MapPopup
                toggle={this.state.togglePopup}
                x={this.state.mouseX}
                y={this.state.mouseY}
                country={this.state.selectedCountry}
              />,
              documentGlobal.body
            )
          : null*/}
      </div>
    )
  }
}

export default WorldMap
