import React from 'react'

import styles from '../css-modules/main-content.module.css'

import SectionTitle from './SectionTitle'
import SectionImage from './SectionImage'

export default ({ content, country, padding }) => {
  return (
    <div
      className={styles.mainContent}
      style={{ padding: `${padding}px 0 60px`,  "background-color": "#12153d"}}
    >
    </div>
  )
}
