import React from 'react'

import AutoComplete from './AutoComplete'

import countries from '../data/countries'
import { navigate } from 'gatsby'

import styles from '../css-modules/search.module.css'

class Search extends React.Component {
  state = {
    query: '',
    dropdown: false,
  }

  handleSearching = e => {
    const query = e.target.value
    this.setState({
      query,
      dropdown: false,
    })
  }

  handleMobileSearching = e => {
    console.log(e.target.value)
    navigate(`/country`, { state: { country: e.target.value } })
  }

  handleSearching = e => {
    const query = e.target.value
    this.setState({
      query,
      dropdown: false,
    })
  }

  handleToggleDropdown = () => {
    this.setState(
      {
        dropdown: !this.state.dropdown,
      },
      () => {
        if (this.state.dropdown) {
          const autocomplete = document.getElementById('autocomplete')
          autocomplete.focus()
        }
      }
    )
  }

  handleAutoCompleteBlur = () => {
    setTimeout(() => {
      this.setState({
        dropdown: false,
        query: '',
      })
      const countrySearch = document.getElementById('countrySearch')
      countrySearch.value = ''
    }, 150)
  }

  render() {
    return (
      <div className="search">
        {this.state.query.length === 0 ? (
          <span onClick={this.handleToggleDropdown} className="dropdown">
            &#9660;
          </span>
        ) : null}
        <input
          className={styles.countrySearch}
          id="countrySearch"
          onChange={this.handleSearching}
          placeholder="Search countries..."
        />
        <select
          className={styles.countrySelectMobile}
          id="countrySelectMobile"
          onChange={this.handleMobileSearching}
        >
          <option
            value="pleaseSelect"
          >
            Please select a country...
          </option>
          {countries.map(country => (
            <option
              value={country}
            >
              {country}
            </option>
          ))}
        </select>
        {this.state.query.length > 0 || this.state.dropdown ? (
          <div
            id="autocomplete"
            onBlur={this.handleAutoCompleteBlur}
            tabIndex={0}
          >
            <AutoComplete type={this.state.dropdown} query={this.state.query} />
          </div>
        ) : null}
      </div>
    )
  }
}

export default Search
