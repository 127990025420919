import React from 'react'

import Title from './Title'
import Search from './Search'
import WorldMap from './WorldMap'

import styles from '../css-modules/header.module.css'

class Header extends React.Component {
  render() {
    return (
      <div className={styles.header}>
        <div className={styles.social}>
          <a href="/">
            <img src="/static/Revision-Insurance_Logo.png" />
          </a>
        </div>
        {/*
        <nav>
          <a href="#top">Home</a>
          &nbsp;&nbsp;&nbsp;
          <a href="#bio">Bio</a>
          &nbsp;&nbsp;&nbsp;
          <a href="#ep">EP</a>
          &nbsp;&nbsp;&nbsp;
          <a href="#contact">Contact</a>
        </nav>
        */}
        <div className={styles.content}>
          <div className="full-spacing">
            <Title style={styles.title} />
          </div>
          <div className="full-spacing">
            <Search style={styles.search} />
          </div>
          <WorldMap />
        </div>
        <img className={styles.nurse} src="/static/ISAPS-site-todd-nurse.png" />
      </div>
    )
  }
}

export default Header
