import React, { Fragment } from 'react'
import Helmet from 'react-helmet'

//import MainContent from '../components/MainContent'
import MainContentHome from '../components/MainContentHome'
import Header from '../components/Header'
import Footer from '../components/Footer'


import '../layouts/index.css'

//import landingContent from '../data/landing-content-blank.json'

var landingContent = {}

      //<MainContent padding={60} content={landingContent} />
export default () => {
  return (
    <div>
      <Helmet
        title="ISAPS Insurance"
      >
        <html lang="en" />
      </Helmet>
      <Header />
      <MainContentHome padding={170} content={landingContent} />
      <Footer />
    </div>
  )
}
