import React from 'react'
import { navigate } from 'gatsby'

import countries from '../data/countries.json'
import countryContent from '../data/country-content'

import styles from '../css-modules/auto-complete.module.css'

class AutoComplete extends React.Component {
  state = {
    query: '',
    countries,
    queriedCountries: [],
  }

  componentWillReceiveProps(newProps) {
    const countries = this.state.countries.filter(country =>
      country.toLowerCase().includes(newProps.query.toLowerCase())
    )
    this.setState({
      query: newProps.query,
      queriedCountries:
        countries.length === 0 ? ['<i>No countries found</i>'] : countries,
    })
  }

  render() {
    return (
      <div className={styles.autoComplete}>
        {(this.props.type
          ? this.state.countries
          : this.state.queriedCountries
        ).map(country => {
          return (
            <p
              onClick={() => navigate(`/country`, { state: { country } })}
              dangerouslySetInnerHTML={{
                __html: country.replace(
                  new RegExp(this.state.query, 'gi'),
                  '<b>$&</b>'
                ),
              }}
              className={styles.country}
            />
          )
        })}
      </div>
    )
  }
}

export default AutoComplete
