import React from 'react'

export default ({ style }) => {
  return (
    <div>
      <div className={style}>Welcome to the home of Revision Insurance</div>
      <div>
        <small>
          Please click on the country where you are a practising surgeon
        </small>
      </div>
    </div>
  )
}
